.input-code__input-description-timer {
  text-align: center;

  color: #9040a1;
}

.input-code__link-send-code {
  display: inline-block;

  margin: 14px 0 0;
  padding: 0;

  cursor: pointer;
  text-align: left;
  text-decoration: none;

  color: #9040a1;
  border: 0;
  outline: none;
  background: none;

  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  line-height: 25px;
}

.input-code__link-send-code:hover {
  color: #ed93ff;
}

.input-code__link-send-code:active {
  color: #561a6f;
}

.input-code__link-send-code:disabled {
  color: #6d6b6b;
}

.input-password__icon-eye {
  position: absolute;
  background: none;
  border: none;
  right: 15px;
  margin-top: 54px;
  color: #561a6f;
}

@media (max-width: 768px) {
  .input-code__link-send-code {
    margin-top: 10px;

    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    line-height: 22px;
  }
  .input-password__icon-eye {
    margin-top: 46px;
  }
}

@media (max-width: 320px) {
  .input-code__link-send-code {
    margin-top: 6px;

    font-size: 14px;
    line-height: 19px;
  }
  .input-password__icon-eye {
    margin-top: 46px;
  }
}
