.reset-pass {
  display: flex;
}

.reset-pass__link-signup {
  position: relative;
  display: flex;
  margin: 79px 10px 0;
  text-decoration: none;
  color: #9040a1;
  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  line-height: 25px;
}

.reset-pass__link-signup:hover {
  color: #ed93ff;
}

.reset-pass__link-signup:active {
  color: #561a6f;
}

.reset-pass__link-signup:disabled {
  color: #6d6b6b;
}

@media (max-width: 768px) {
  .reset-pass__link-signup {
    margin-top: 56px;
    font-size: 16px;
    line-height: 22px;
  }
}

@media (max-width: 320px) {
  .reset-pass__link-signup {
    margin: 34px 0 0;
    font-size: 14px;
    line-height: 19px;
  }
}
