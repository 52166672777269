.button {
  align-items: center;

  justify-content: center;

  width: 100%;
  margin-top: 41px;
  padding: 17px 0;

  cursor: pointer;

  color: #fff;
  border: 0;
  border-radius: 30px;

  background:
    linear-gradient(
      180deg,
      #ed223c 0%,
      #ee4135 43.22%,
      #ee4b32 57.59%,
      #ee5230 71.59%,
      #ef622b 102.07%,
      #ef7723 123.85%
    );
  box-shadow: 0 2px 8px rgb(65 37 90 / 50%);

  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  line-height: 30px;
}

.button:hover {
  cursor: pointer;
  transition: opacity 0.2s linear;

  opacity: 0.6;

  background: linear-gradient(180deg, #fb485f 0%, #f98738 123.85%);
}

.button:active {
  cursor: pointer;
  transition: opacity 0.2s linear;

  opacity: 0.6;

  background:
    linear-gradient(
      180deg,
      #ed223c 0%,
      #ee4135 43.22%,
      #ee4b32 57.59%,
      #ee5230 71.59%,
      #ef622b 102.07%,
      #ef7723 123.85%
    );
  box-shadow: 0 2px 8px rgb(65 37 90 / 50%);
}

.button:disabled {
  cursor: none;
  pointer-events: none;

  background:
    linear-gradient(
      180deg,
      #ed223c 0%,
      #ee4135 43.22%,
      #ee4b32 57.59%,
      #ee5230 71.59%,
      #ef622b 102.07%,
      #ef7723 123.85%
    );
  box-shadow: 0 2px 8px rgb(65 37 90 / 50%);
}

@media (max-width: 768px) {
  .button {
    padding: 13px;

    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }
}

@media (max-width: 320px) {
  .button {
    padding: 10px;

    font-size: 14px;
    line-height: 19px;
  }
}
