.review {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  width: 100%;

  padding-top: 107px;
}

.review__stars {
  display: flex;
  align-items: center;
  flex-basis: max-content;
  justify-content: center;
}

.review__title {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 23px 0 0;

  color: #fff;

  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 25px;
}

.review__rating {
  display: flex;
  flex-direction: row;

  width: 100%;
  margin: 0 0 17px;
  padding: 0;
}

.review__result-text-ok {
  width: max-content;
  margin: 125px 0 0;

  color: #fae1ff;

  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 25px;
  font-weight: 600;
  font-style: normal;
  line-height: 34px;
}

.review__button-submit {
  flex: none;
  flex-grow: 0;
  order: 0;

  width: 100%;
  margin: 36px 0 0;
  padding: 17px;

  cursor: pointer;

  color: #fae1ff;

  border: 0;
  border-radius: 10px;
  outline: none;

  background: #9040a1;
  box-shadow: 2px 2px 7px rgb(70 38 98 / 25%);

  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 22px;

  font-weight: normal;
  font-style: normal;
  line-height: 30px;
}

.review__button-submit:hover {
  border-radius: 10px;
  background: #bb6bd9;
  box-shadow: 2px 2px 7px rgb(70 38 98 / 25%);
}

.review__button-submit:active {
  border-radius: 10px;
  background: #9040a1;
  box-shadow: 2px 2px 7px rgb(70 38 98 / 25%);
}

.review__button-submit:disabled {
  color: #ed93ff;
  border-radius: 10px;
  background: #fae1ff;
}

.review__error {
  display: flex;

  width: 100%;
  padding: 8px 0 0;

  color: #fff;

  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  line-height: 16px;
}

.review__textarea-text::placeholder {
  color: #fae1ff;
}

.review__textarea-text {
  box-sizing: border-box;
  width: 100%;
  height: 271px;
  padding: 25px 40px;

  color: #fff;
  border: 1px solid #fff;
  border-radius: 10px;
  background: none;

  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: 19px;
}

.review__textarea-error {
  border: 1px solid #f0392f;
}

@media (max-width: 768px) {
  .review {
    padding: 72px 0 40px;
  }

  .review__button-submit {
    margin: 16px 0 0;
    padding: 11px;

    border-radius: 5px;

    font-size: 12px;
    line-height: 16px;
  }

  .review__title {
    font-size: 12px;
    line-height: 16px;
  }

  .review__textarea-text {
    height: 134px;
    padding: 15px;

    font-size: 10px;
    line-height: 14px;
  }

  .review__result-text-ok {
    margin: 44px 0 0;

    color: #fff;

    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
  }

  .review__error {
    padding: 8px 0 0;

    font-size: 12px;
    line-height: 16px;
  }
}

@media (max-width: 320px) {
  .review {
    padding: 59px 0 32px;
  }

  .review__button-submit {
    margin: 16px 0 0;
    padding: 11px;

    border-radius: 5px;

    font-size: 12px;
    line-height: 16px;
  }

  .review__title {
    font-size: 12px;
    line-height: 16px;
  }

  .review__textarea-text {
    height: 134px;
    padding: 15px;

    font-size: 10px;
    line-height: 14px;
  }

  .review__result-text-ok {
    margin: 44px 0 0;

    color: #fff;

    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
  }

  .review__error {
    padding: 8px 0 0;

    font-size: 12px;
    line-height: 16px;
  }
}
