.footer {
  display: flex;
  align-items: stretch;
  flex: 0 0 auto;
  flex-wrap: nowrap;
  justify-content: center;

  box-sizing: border-box;
  width: 100%;
  max-width: 506px;
  margin: 0;
  padding: 34px 0 14px;
}

.footer__nav {
  display: flex;
  align-items: center;

  height: content-box;
  margin: 0;
  padding: 0;
}

.footer__item {
  display: flex;

  margin: 0 7px 0 0;
  padding: 0;
}

.footer__item:last-of-type {
  margin-right: 0;
}

.footer__items {
  display: flex;

  flex-direction: row;

  margin: 0;

  padding: 0;

  list-style: none;

  vertical-align: center;
}

.footer__link {
  display: flex;

  margin: 0;
  padding: 0;

  list-style: none;

  text-decoration: none;
}

.footer__social-icon {
  width: 27px;
  height: 27px;
}

.footer__social-icon:hover .path2 {
  fill: #ed93ff;
}

.footer__social-icon:active .path2 {
  fill: #561a6f;
}

.footer__social-icon-disabled path {
  fill: #bdbdbd;
}

.footer__social-icon-disabled .path2 {
  fill: #fff;
}

.footer__title {
  display: flex;
  align-items: center;

  margin: 0 20px 0 0;
  padding: 0;

  color: #fff;

  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  line-height: 25px;
}

@media (max-width: 768px) {
  .footer {
    align-items: center;
    flex-direction: column;

    padding: 64px 0 0;
  }

  .footer__nav {
    margin-top: 15px;
  }

  .footer__social-icon {
    width: 30px;
    height: 30px;
  }

  .footer__item {
    margin: 0 22px 0 0;
  }

  .footer__title {
    margin-right: 0;

    text-align: center;

    font-size: 15px;
    line-height: 20px;
  }
}

@media (max-width: 320px) {
  .footer {
    padding: 54px 0 0;
  }

  .footer__nav {
    margin-top: 12px;
  }

  .footer__social-icon {
    width: 30px;
    height: 30px;
  }

  .footer__item {
    margin: 0 18px 0 0;
  }

  .footer__title {
    font-size: 12px;
    line-height: 16px;
  }
}
