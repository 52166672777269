.review-star__radio {
  display: none;

  margin: 0;
  padding: 0;
}

.review-star__image {
  width: 26px;
}

.review-star__image:hover {
  cursor: pointer;
}

.review-star__image:hover path {
  stroke: #ed93ff;
}

.review-star__image:active path {
  stroke: #fae1ff;
  fill: #fae1ff;
}

.review-star__image-hover path {
  stroke: #ed93ff;
}

.review-star__image-hover {
  background: #ed93ff;
}

.review-star__image-active path {
  stroke: #fae1ff;
  fill: #fae1ff;
}

.review-star__image-disabled {
  fill: #bdbdbd;
}

.review-star__image-disabled path {
  fill: #bdbdbd;
}

.review-star__image-full {
  background: url("../images/StarFull.svg") no-repeat center center;
}

@media (max-width: 768px) {
  .review-star__image {
    width: 22px;
  }
}

@media (max-width: 320px) {
  .review-star__image {
    width: 18px;
  }
}
