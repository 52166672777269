.auth {
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: column;

  box-sizing: border-box;
  width: 100%;
  max-width: 506px;
  margin: 0;

  padding: 36px 42px 56px;

  border-radius: 15px;
  background: #fff;

  box-shadow: 3px 3px 10px #874494;
}

.auth__title {
  margin: 0 0 28px;
  padding: 0;

  color: #30004d;

  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 40px;
  font-weight: 600;
  font-style: normal;
  line-height: 68px;
}

@media (max-width: 768px) {
  .auth {
    max-width: 100%;
    padding: 32px 20px 46px;

    border-radius: 12px;
  }

  .auth__title {
    margin-bottom: 24px;

    font-size: 30px;
    line-height: 47px;
  }
}

@media (max-width: 320px) {
  .auth {
    padding: 31px 13px 36px;

    border-radius: 10px;
  }

  .auth__title {
    margin-bottom: 21px;

    font-size: 20px;
    line-height: 27px;
  }
}
