.logo {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;

  max-width: 253px;
}

@media (max-width: 768px) {
  .logo {
    position: static;

    max-width: 170px;
  }
}

@media (max-width: 320px) {
  .logo {
    max-width: 100px;
  }
}
