.signup__link-box {
  display: flex;

  max-width: max-content;
  padding: 72px 0 0;
}

.signup__link-box-title {
  display: inline-block;

  margin: 0;
  padding: 0;

  color: #393838;

  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 25px;
}

.signup__link-signin {
  margin: 0 0 0 6px;

  text-decoration: none;

  color: #9040a1;

  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  line-height: 25px;
}

.signup__link-signin:hover {
  color: #ed93ff;
}

.signup__link-signin:active {
  color: #561a6f;
}

.signup__link-signin:disabled {
  color: #6d6b6b;
}

@media (max-width: 768px) {
  .signup__link-box {
    align-items: center;
    flex-direction: column;

    padding: 54px 0 0;
  }

  .signup__link-box-title {
    text-align: center;

    font-size: 15px;
    line-height: 20px;
  }

  .signup__link-signin {
    margin-top: 7px;
    margin-left: 0;

    font-size: 18px;
    line-height: 25px;
  }
}

@media (max-width: 320px) {
  .signup__link-box {
    padding: 34px 0 0;
  }

  .signup__link-box-title {
    font-size: 12px;
    line-height: 16px;
  }

  .signup__link-signin {
    margin-top: 7px;
    margin-left: 0;

    font-size: 14px;
    line-height: 19px;
  }
}
