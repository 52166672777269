.input-box {
  display: flex;
  flex-direction: column;

  width: 100%;
}

.input-box__title {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;

  text-align: left;

  color: #393838;

  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 25px;
}

.input-box__input {
  box-sizing: border-box;
  width: 100%;
  margin: 9px 0;
  padding: 20px 24px;

  color: #393838;

  border: 1px solid #bdbdbd;
  border-radius: 10px;

  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 25px;
}

.input-box__input:hover {
  box-sizing: border-box;

  border: 1px solid #9040a1;
  border-radius: 10px;
  background: #fff;
}

.input-box__input:active {
  box-sizing: border-box;

  border: 1px solid #9040a1;
  border-radius: 10px;
  background: #fff;
}

.input-box__input:disabled {
  box-sizing: border-box;

  border: 1px solid #bdbdbd;
  border-radius: 10px;
  background: #f6f3f6;
}

.input-box__input-error {
  border: 1px solid #f0392f;
}

.input-box__error {
  display: flex;

  color: #f0392f;

  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  line-height: 16px;
}

.input-box__input-description {
  display: flex;

  color: #6d6b6b;

  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  line-height: 16px;
}

@media (max-width: 768px) {
  .input-box__title {
    font-size: 15px;
    line-height: 20px;
  }

  .input-box__input {
    margin: 8px 0;
    padding: 15px 19px;

    font-size: 16px;
    line-height: 22px;
  }

  .input-box__error {
    font-size: 11px;
    line-height: 15px;
  }

  .input-box__input-description {
    font-size: 11px;
    line-height: 15px;
  }
}

@media (max-width: 320px) {
  .input-box__title {
    font-size: 12px;
    line-height: 16px;
  }

  .input-box__input {
    margin: 7px 0;
    padding: 11px 14px;

    font-size: 14px;
    line-height: 19px;
  }

  .input-box__error {
    font-size: 10px;
    line-height: 14px;
  }

  .input-box__input-description {
    font-size: 10px;
    line-height: 14px;
  }
}
