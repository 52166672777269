.faq-questions-card {
  display: flex;
  flex-direction: column;

  width: 100%;
  margin: 0 0 16px;
  padding: 0;

  color: #9040a1;
}

.faq-questions-card:last-of-type {
  margin-bottom: 0;
}

.faq-questions-card__answer {
  display: none;

  box-sizing: border-box;
  margin: 10px 0 0;

  padding: 44px 98px 44px 39px;

  text-align: left;

  color: #fff;
  border: 1px solid #fae1ff;
  border-radius: 10px;

  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 25px;
}

.faq-questions-card__answer p {
  margin: 0;
  padding: 0;
}

.faq-questions-card__answer-visible {
  display: block;
}

.faq-questions-card__title {
  display: block;

  margin: 0;
  padding: 30px 37px;

  cursor: pointer;
  text-align: left;

  color: #9040a1;
  border: none;
  border-radius: 10px;
  outline: none;

  background: #fae1ff url("../images/FaqArrow.svg") no-repeat center right 31px;
  background-size: 24px 20px;

  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 25px;
  font-weight: 600;
  font-style: normal;
  line-height: 34px;
}

.faq-questions-card__title:hover {
  background-color: #fff;
}

.faq-questions-card__title:active {
  background-color: #fae1ff;
}

.faq-questions-card__title-disabled {
  pointer-events: none;

  color: #fff;

  background-color: #bdbdbd;
  background-image: url("../images/FaqArrowDisabled.svg");
}

.faq-questions-card__title-visible {
  background-image: url("../images/FaqArrowViewed.svg");
}

@media (max-width: 768px) {
  .faq-questions-card {
    margin: 0 0 3px;
    padding: 0;
  }

  .faq-questions-card__title {
    padding: 12px;

    border-radius: 5px;

    background-position: right 24px center;
    background-size: 17px 14px;

    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
  }

  .faq-questions-card__answer {
    margin-bottom: 7px;
    padding: 29px 46px 34px 18px;

    border-radius: 5px;

    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
  }
}

@media (max-width: 320px) {
  .faq-questions-card {
    margin: 0 0 3px;
    padding: 0;
  }

  .faq-questions-card__title {
    padding: 12px;

    border-radius: 5px;

    background-position: right 16px center;
    background-size: 10px 8px;

    font-size: 12px;
    line-height: 16px;
  }

  .faq-questions-card__answer {
    padding: 29px 46px 34px 18px;

    border-radius: 5px;

    font-size: 12px;
    line-height: 16px;
  }
}
