.react-tel-input .form-control {
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  padding: 20px 24px 20px 67px;

  color: #393838;

  border: 1px solid #bdbdbd;
  border-radius: 10px;

  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 25px;
}

.react-tel-input .form-control:focus {
  border: 1px solid #9040a1;
}

.react-tel-input .form-control:hover {
  border: 1px solid #9040a1;
}

.react-tel-input .flag-dropdown {
  margin: 21px 24px;

  border: none;
  background: none;
}

.input-phone__containerClass {
  margin: 9px 0;
}

@media (max-width: 768px) {
  .react-tel-input .form-control {
    padding: 15px 19px 15px 51px;

    font-size: 16px;

    line-height: 22px;
  }

  .react-tel-input .flag-dropdown {
    margin: 21px 24px 21px 12px;
  }

  .input-phone__containerClass {
    margin: 8px 0;
  }
}

@media (max-width: 320px) {
  .react-tel-input .form-control {
    padding: 11px 14px 11px 46px;

    font-size: 14px;
    line-height: 19px;
  }

  .react-tel-input .flag-dropdown {
    margin: 21px 24px 21px 6px;
  }

  .input-phone__containerClass {
    margin: 7px 0;
  }
}
