.responsive-player {
  position: relative;

  width: 100%;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.responsive-player__player {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;

  background: url("../images/backgroundPlayer.png") no-repeat center center;
}

.responsive-player__player-play {
  width: 60px;
  height: 60px;

  background: url("../images/Play.svg") no-repeat center center;
  background-size: 100% 100%;
}

.responsive-player__player-play:hover {
  background: url("../images/PlayHover.svg") no-repeat center center;
  background-size: 100% 100%;
}

.responsive-player__player-play:active {
  background: url("../images/PlayActive.svg") no-repeat center center;
  background-size: 100% 100%;
}

.responsive-player__player-play:disabled {
  background: url("../images/PlayDisabled.svg") no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 768px) {
  .responsive-player__player-play {
    max-width: 39px;
    max-height: 39px;
  }
}

@media (max-width: 320px) {
  .responsive-player__player-play {
    max-width: 29px;
    max-height: 29px;
  }
}
