.faq-question-list {
  position: relative;

  display: flex;

  flex-direction: column;

  width: 100%;
  margin: 0;

  padding: 0;

  list-style: none;

  text-align: left;
  vertical-align: center;
}
