.task__button_complete {
  flex: none;

  flex-grow: 0;
  order: 0;

  margin: 0 10px;
  padding: 17px 28px;

  cursor: pointer;

  color: #fae1ff;
  border: 0;
  border-radius: 30px;

  background:
    linear-gradient(
      180deg,
      #ed223c 0%,
      #ed2e39 17.39%,
      #ed3637 28.4%,
      #ee4135 43.22%,
      #ee4b32 57.59%,
      #ee5230 71.59%,
      #ef622b 102.07%,
      #ef7723 123.85%
    );

  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  line-height: 30px;
}

.task__button_complete:hover {
  background:
    linear-gradient(
      180deg,
      #fb485f 0%,
      #f98738 123.85%
    );
  box-shadow: 0 2px 8px rgb(65 37 90 / 50%);
}

.task__button_complete:active {
  background:
    linear-gradient(
      180deg,
      #ed223c 0%,
      #ee4135 43.22%,
      #ee4b32 57.59%,
      #ee5230 71.59%,
      #ef622b 102.07%,
      #ef7723 123.85%
    );
  box-shadow: 0 2px 8px rgb(65 37 90 / 50%);
}

.task__button_complete:disabled {
  background:
    linear-gradient(
      180deg,
      #ed223c 0%,
      #ed2e39 17.39%,
      #ed3637 28.4%,
      #ee4135 43.22%,
      #ee4b32 57.59%,
      #ee5230 71.59%,
      #ef622b 102.07%,
      #ef7723 123.85%
    );
}

.task {
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 1082px;
}

.task__box {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  box-sizing: border-box;
  padding: 97px 129px 121px;

  border: 1px solid #fff;
  border-radius: 15px;
  filter: drop-shadow(3px 3px 10px #874494);
}

.task__title {
  margin: 0 0 45px;
  padding: 0;

  color: #fae1ff;

  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 50px;
  font-weight: 600;
  font-style: normal;
  line-height: 68px;
}

.task__done-text {
  margin: 48px 0 0;
  padding: 17px 10px;

  text-align: center;

  color: #fae1ff;

  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  line-height: 30px;
}

.task__form-complete {
  margin: 48px 0 0;
  padding: 0;
}

.task__search-form {
  display: flex;
  align-self: flex-end;

  flex-basis: 210px;

  width: calc(100% - 159px);
}

@media (max-width: 768px) {
  .task__search-form {
    flex-basis: 110px;

    width: 100%;
  }

  .task__box {
    padding: 0;

    border: 0;
  }

  .task__title {
    margin-bottom: 23px;

    padding: 0;

    text-align: center;

    color: #fae1ff;

    font-size: 35px;
    font-weight: 600;
    line-height: 45px;
  }

  .task__player-play {
    max-width: 39px;
    max-height: 39px;
  }

  .task__form-complete {
    margin: 13px 0 0;
    padding: 0;
  }

  .task__button_complete {
    margin: 0;
    padding: 11px;

    color: #fae1ff;

    font-size: 14px;
    line-height: 18px;
  }

  .task__done-text {
    margin: 13px 0 0;
    padding: 11px 10px;

    font-size: 14px;
    line-height: 18px;
  }
}

@media (max-width: 320px) {
  .task__search-form {
    flex-basis: 63px;
  }

  .task__title {
    margin-bottom: 23px;

    padding: 0;

    color: #fae1ff;

    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
  }

  .task__player-play {
    max-width: 29px;
    max-height: 29px;
  }

  .task__form-complete {
    margin: 13px 0 0;
    padding: 0;
  }

  .task__button_complete {
    margin: 0;
    padding: 11px;

    color: #fae1ff;

    font-size: 12px;
    line-height: 16px;
  }

  .task__done-text {
    margin: 13px 0 0;
    padding: 11px 10px;

    font-size: 12px;
    line-height: 16px;
  }
}
