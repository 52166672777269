.fieldset {
  display: grid;
  grid-row-gap: 27px;

  width: 100%;

  margin: 0;
  padding: 0;

  border: 0;
}

@media (max-width: 768px) {
  .fieldset {
    grid-row-gap: 23px;
  }
}

@media (max-width: 320px) {
  .fieldset {
    grid-row-gap: 20px;
  }
}
