.search {
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
  max-width: 656px;

  margin: auto;
  padding: 0;
}

.search__title {
  width: 100%;
  margin: 0;
  padding: 0;

  text-align: center;
  white-space: pre-line;

  color: #fae1ff;

  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 40px;
  font-weight: 600;
  font-style: normal;
  line-height: 55px;
}

.search__description {
  width: 100%;
  max-width: 492px;
  margin: 25px 0 57px;

  padding: 0;

  white-space: pre-line;

  color: #fff;

  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 25px;
}

.search__player {
  width: 100%;

  margin-top: 42px;
}

.search__player-title {
  margin: 12px 0;

  padding: 0;

  text-align: center;

  white-space: pre-line;

  color: #fff;

  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 25px;
}

@media (max-width: 768px) {
  .search {
    padding: 0 0 35px;
  }

  .search__title {
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
  }

  .search__description {
    margin-bottom: 44px;

    text-align: center;

    font-size: 15px;
    font-weight: normal;
    line-height: 20px;
  }

  .search__player {
    margin-top: 14px;
  }

  .search__player-title {
    margin-bottom: 10px;

    text-align: center;

    font-size: 15px;
    font-weight: normal;
    line-height: 20px;
  }
}

@media (max-width: 320px) {
  .search {
    padding: 0 0 20px;
  }

  .search__title {
    font-size: 20px;
    line-height: 25px;
  }

  .search__description {
    margin-bottom: 34px;

    font-size: 12px;
    line-height: 16px;
  }

  .search__player {
    margin-top: 0;
  }

  .search__player-title {
    font-size: 12px;
    line-height: 16px;
  }
}
