.faq {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  padding: 148px 0 0;
}

.faq__title {
  margin: 0 0 37px;
  padding: 0;

  color: #fae1ff;

  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 40px;
  font-weight: 600;
  font-style: normal;
  line-height: 55px;
}

@media (max-width: 768px) {
  .faq {
    padding: 52px 0 0;
  }

  .faq__title {
    margin: 0 0 28px;

    font-size: 30px;
    font-weight: normal;
    line-height: 40px;
  }
}

@media (max-width: 320px) {
  .faq {
    padding: 38px 0 0;
  }

  .faq__title {
    margin: 0 0 21px;

    font-size: 20px;
    line-height: 27px;
  }
}
