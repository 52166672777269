.app {
  position: relative;

  display: flex;
  overflow: hidden;
  align-items: center;

  flex: 1 0 auto;
  flex-direction: column;

  box-sizing: border-box;
  width: 100%;
  max-width: 1440px;

  min-height: 100vh;

  margin: 0 auto;
  padding: 56px 20px 70px;

  font-family: Nunito, Arial, Helvetica, sans-serif;
  text-rendering: optimizeLegibility;
}

.app__all {
  position: absolute;
  z-index: -10;
  top: 0;
  left: 0;

  width: 100%;

  /* height: 100%; */

  background:
    linear-gradient(
      85.39deg,
      #2d1152 -1.56%,
      #983f9f 102.44%
    );
}

.app__task {
  width: 100%;
  height: 100%;

  background:
    linear-gradient(
      150.76deg,
      #2d1152 -5.68%,
      #592472 45.27%,
      #5d2675 50.49%,
      #622878 62.11%,
      #682a7c 75.01%,
      #6d2c80 82.41%,
      #7c338b 94.92%,
      #983f9f 118.3%
    );
}

.app__background-image {
  position: absolute;
  z-index: -5;
  right: -350px;
  bottom: -130px;
}

.app__background-line {
  position: absolute;
  z-index: -10;

  right: -472px;
  bottom: 60px;
}

.app__background-line-two {
  position: absolute;
  z-index: -10;
  right: -490px;
  bottom: -120px;
}

.app__background-vector {
  position: absolute;
  z-index: -10;
  right: -400px;
  bottom: -220px;
}

.app__background-vector-two {
  position: absolute;
  z-index: -10;
  bottom: -200px;
  left: -330px;
}

.app__background-illustration {
  position: absolute;
  z-index: -10;
  right: -300px;
  bottom: 270px;
}
.app__logout {
  border: none;
  background: none;
}

@media (max-width: 768px) {
  .app {
    padding: 14px 20px 60px;
  }
}

@media (max-width: 320px) {
  .app {
    padding: 10px 15px 55px;
  }
}
