.login__link-signup {
  display: block;

  margin: 79px 0 0;

  text-decoration: none;

  color: #9040a1;

  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  line-height: 25px;
}

.login__link-signup:hover {
  color: #ed93ff;
}

.login__link-signup:active {
  color: #561a6f;
}

.login__link-signup:disabled {
  color: #6d6b6b;
}

.price-v {
  box-shadow: 0 0 5px 1px rgb(0 0 0 / 8%);
  border-radius: 2.5rem;
  margin-bottom: 2.5rem;
}
.price-v .nav-link {
  border-radius: 2.5rem !important;
  border: 1px solid transparent;
  color: #6c757d !important;
  width: 100px;
  text-align: center;
}
.price-v .nav-link.active, .price-v .show>.nav-link {
  color: #9040a1 !important;
  background-color: #ffffff !important;
  border: 1px solid #9040a1 !important;
  transition: background-color 0.2s ease 0s, border-color 0.2s ease 0s;
}

.login__link-reset{
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  text-decoration: none;
  color: #393838;
  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: 25px;
}

.login__link-reset:hover{
  text-decoration: underline;
  color: #393838;
}

@media (max-width: 768px) {
  .login__link-signup {
    margin-top: 56px;

    font-size: 16px;
    line-height: 22px;
  }
}

@media (max-width: 320px) {
  .login__link-signup {
    margin: 34px 0 0;

    font-size: 14px;
    line-height: 19px;
  }
}
