.search-form {
  display: flex;

  flex-direction: column;

  width: 100%;
}

.search-form__form {
  position: relative;

  display: flex;

  margin: 0;
}

.search-form__input-text::placeholder {
  color: #6d6b6b;
}

.search-form__input-text:disabled {
  background: #fae1ff;
}

.search-form__input-text:hover:not([disabled]) {
  background: #fff;
}

.search-form__input-text:focus {
  background: #fae1ff;
}

.search-form__input-text:focus:hover {
  background: #fae1ff;
}

.search-form__input-text {
  box-sizing: border-box;
  width: 100%;
  padding: 20px 25px;

  color: #393838;
  border: 1px solid #bbbcbc;
  border-radius: 10px;

  background: #fff;

  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 25px;
}

.search-form__input-text-error {
  /* color: #f0392f; */
}

.search-form__input-text-error::placeholder {
  color: #f0392f;
}

.search-form__input-text-task {
  background: #fae1ff;
}

.search-form__button-submit {
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  width: 100%;
  max-width: 212px;
  height: 100%;
  padding: 17px 71px;

  color: #fff;
  border: 1px solid #fff;
  border-radius: 10px;

  background:
    linear-gradient(
      180deg,
      #ed223c 0%,
      #ed2e39 17.39%,
      #ed3637 28.4%,
      #ee4135 43.22%,
      #ee4b32 57.59%,
      #ee5230 71.59%,
      #ef622b 102.07%,
      #ef7723 123.85%
    );

  font-family: Nunito, Arial, Helvetica, sans-serif;
}

.search-form__button-submit:hover {
  box-sizing: border-box;

  cursor: pointer;

  border: 1px solid #fff;
  border-radius: 10px;
  background:
    linear-gradient(
      180deg,
      #fb485f 0%,
      #f98738 123.85%
    );
}

.search-form__button-submit:active {
  box-sizing: border-box;

  border: 1px solid #fff;
  border-radius: 10px;
  background:
    linear-gradient(
      180deg,
      #ed223c 0%,
      #ed2e39 17.39%,
      #ed3637 28.4%,
      #ee4135 43.22%,
      #ee4b32 57.59%,
      #ee5230 71.59%,
      #ef622b 102.07%,
      #ef7723 123.85%
    );
}

.search-form__button-submit:disabled {
  box-sizing: border-box;

  pointer-events: none;

  color: #fff;

  border: 1px solid #fff;
  border-radius: 10px;
  background: #bdbdbd;
}

.search-form__input-error {
  display: flex;

  min-height: 25px;

  padding: 8px 0 0;

  color: #fff;

  font-family: Nunito, Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 22px;
}

@media (max-width: 768px) {
  .search-form {
    /*padding-bottom: 30px;*/
    padding-bottom: 10px;
  }

  .search-form__input-text {
    padding: 13px 16px 13px 11px;

    border-radius: 7px;

    font-size: 10px;
    font-weight: normal;
    line-height: 14px;
  }

  .search-form__button-submit {
    max-width: min-content;
    padding: 11px 22px;

    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
  }

  .search-form__input-error {
    padding: 8px 0 0;

    font-size: 14px;
    font-weight: normal;
    line-height: 26px;
  }
}

@media (max-width: 320px) {
  .search-form {
    /*padding-bottom: 20px;*/
    padding-bottom: 10px;
  }

  .search-form__input-text {
    padding: 13px 16px 13px 11px;

    border-radius: 5px;

    font-size: 10px;
    line-height: 14px;
  }

  .search-form__button-submit {
    padding: 11px 22px;

    border-radius: 5px;

    font-size: 12px;
    line-height: 16px;
  }

  .search-form__input-error {
    padding: 7px 0 0;

    font-size: 10px;
    line-height: 14px;
  }
}
